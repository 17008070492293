class PageDataMappingController {
  constructor() {}
  mapData(data, map, sectionData) {
    let newSectionData = { ...sectionData };
    delete newSectionData["dataRef"];
    delete newSectionData["mapping"];
    Object.keys(map).forEach((k) => {
      if (data[map[k]]) {
        newSectionData[k] = data[map[k]];
      }
    });
    return newSectionData;
  }
}

export default PageDataMappingController;
